import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  createWorkCheckinThunk,
  createWorkCheckoutThunk,
  getAllWorkCheckinOutThunk,
} from "./workCheckinOutThunk";
const initialState = {
  isLoading: false,
  name: "",
  start: new Date(),
  end: new Date(),
  workChecinOuts: [],
};

export const getAllWorkCheckinOut = createAsyncThunk(
  "workCheckinOut/getAllWorkCheckinOut",
  getAllWorkCheckinOutThunk
);
export const createWorkCheckin = createAsyncThunk(
  "workCheckinOut/createWorkCheckin",
  createWorkCheckinThunk
);
export const createWorkCheckout = createAsyncThunk(
  "workCheckinOut/createWorkCheckout",
  createWorkCheckoutThunk
);

const workCheckinOutSlice = createSlice({
  name: "workCheckinOut",
  initialState,
  reducers: {
    handleChange: (state, { payload: { name, value } }) => {
      state[name] = value;
    },
    handleChangeStart: (state, { payload: value }) => {
      console.log("handele start");
      state.start = value;
    },
    handleChangeEnd: (state, { payload: value }) => {
      state.end = value;
    },
    clearValues: () => {
      return {
        ...initialState,
      };
    },
    setEditWorkEvent: (state, { payload }) => {
      return { ...state, isEditing: true, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createWorkCheckin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createWorkCheckin.fulfilled, (state) => {
        state.isLoading = false;
        toast.success("Du er checket ind");
      })
      .addCase(createWorkCheckin.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(createWorkCheckout.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createWorkCheckout.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        toast.success(payload);
      })
      .addCase(createWorkCheckout.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(getAllWorkCheckinOut.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllWorkCheckinOut.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.workChecinOuts = payload.workCheckinOuts;
      })
      .addCase(getAllWorkCheckinOut.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      });
  },
});

export const {
  handleChange,
  handleChangeStart,
  handleChangeEnd,
  clearValues,
  setEditWork,
} = workCheckinOutSlice.actions;

export default workCheckinOutSlice.reducer;
