export const addUserToLocalStorage = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const removeUserFromLocalStorage = () => {
  localStorage.removeItem("user");
};

export const getUserFromLocalStorage = () => {
  const result = localStorage.getItem("user");
  const user = result ? JSON.parse(result) : null;
  return user;
};
export const addEmployeeToLocalStorage = (user) => {
  localStorage.setItem("employee", JSON.stringify(user));
};

export const removeEmployeeFromLocalStorage = () => {
  localStorage.removeItem("employee");
};

export const getEmployeeFromLocalStorage = () => {
  const result = localStorage.getItem("employee");
  const employee = result ? JSON.parse(result) : null;
  return employee;
};
