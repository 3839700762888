import NavLinks from "./NavLinks";
import { FaUserCircle, FaCaretDown } from "react-icons/fa";
import { useState } from "react";
import Wrapper from "../assets/wrappers/BigSidebar";
import { useDispatch, useSelector } from "react-redux";
import { clearStore } from "../features/employee/employeeSlice";

const BigSidebar = () => {
  const { isSidebarOpen } = useSelector((store) => store.user);
  const { employee } = useSelector((store) => store.employee);
  const [showLogout, setShowLogout] = useState(false);
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <div
        className={
          isSidebarOpen
            ? "sidebar-container "
            : "sidebar-container show-sidebar"
        }
      >
        <div className="content">
          <header>{/* <Logo /> */}</header>
          <div className="btn-container nav-links">
            <button
              type="button"
              className="btn"
              onClick={() => setShowLogout(!showLogout)}
            >
              <FaUserCircle />
              {employee?.name}
              <FaCaretDown />
            </button>
            <div className={showLogout ? "dropdown show-dropdown" : "dropdown"}>
              <button
                type="button"
                className="dropdown-btn"
                onClick={() => dispatch(clearStore("Logging out..."))}
              >
                logout
              </button>
            </div>
          </div>
          <NavLinks />
        </div>
      </div>
    </Wrapper>
  );
};
export default BigSidebar;
