import { FaHourglassStart, FaHourglassEnd, FaStopwatch } from "react-icons/fa";
import Wrapper from "../assets/wrappers/WorkedHour";
import moment from "moment";
import "moment/locale/da";
import WorkedHourInfo from "./WorkedHourInfo";
// import { deleteJob, setEditJob } from '../features/job/jobSlice';
const WorkedResult = ({ workedHours, fra, til }) => {
  const start = moment(fra);
  const end = moment(til);

  // Initialize total hours and minutes
  let totalHours = 0;
  let totalMinutes = 0;

  // Iterate through each work period
  workedHours.forEach((period) => {
    const start = moment(period.start);
    const end = moment(period.end);

    // Calculate the duration for the current period
    const duration = moment.duration(end.diff(start));

    // Add the duration to total hours and minutes
    totalHours += duration.hours();
    totalMinutes += duration.minutes();
  });

  // Convert excess minutes to hours if needed
  if (totalMinutes >= 60) {
    const extraHours = Math.floor(totalMinutes / 60);
    totalHours += extraHours;
    totalMinutes -= extraHours * 60;
  }

  const result = `${totalHours}:${totalMinutes < 10 ? "0" : ""}${totalMinutes}`;

  return (
    <Wrapper>
      <header>
        <div className="info">
          <h5>{workedHours[0].name} har arbejdet i perioden</h5>
        </div>
      </header>
      <div className="content">
        <div className="content-center">
          <WorkedHourInfo
            icon={<FaHourglassStart />}
            text={start.format("Do MMM YYYY")}
          />
          <WorkedHourInfo
            icon={<FaHourglassEnd />}
            text={end.format("Do MMM YYYY")}
          />
          <WorkedHourInfo icon={<FaStopwatch />} text={result} />
        </div>
      </div>
    </Wrapper>
  );
};
export default WorkedResult;
