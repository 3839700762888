import customFetch, { checkForUnauthorizedResponse } from "../../utils/axios";
import { logoutEmployee } from "./employeeSlice";
export const registerEmployeeThunk = async (url, employee, thunkAPI) => {
  try {
    const resp = await customFetch.post(url, employee);
    console.log("respone is:", resp);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
};

export const loginEmployeeThunk = async (url, employee, thunkAPI) => {
  try {
    const resp = await customFetch.post(url, employee);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
};

export const updateEmployeeThunk = async (url, employee, thunkAPI) => {
  try {
    const resp = await customFetch.patch(url, employee);
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const clearStoreThunk = async (message, thunkAPI) => {
  try {
    thunkAPI.dispatch(logoutEmployee(message));
    return Promise.resolve();
  } catch (error) {
    return Promise.reject();
  }
};
