import React from "react";
import { SearchContainer, WorkedHourContainer } from "../../components";

const HoursWorked = () => {
  return (
    <>
      <SearchContainer />
      <WorkedHourContainer />
    </>
  );
};

export default HoursWorked;
