import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getAllWorkedHourForEmployeeThunk,
  getAllWorkedHourThunk,
  getEmployeesForUserThunk,
  updateHourThunk,
} from "./workedHourThunk";
let currentDate = new Date();
let tomorrowDate = new Date(currentDate);
tomorrowDate.setDate(currentDate.getDate() + 1);
const initialFiltersState = {
  name: "",
  fra: new Date(),
  til: tomorrowDate,
  start: new Date(),
  end: new Date(),
};
const initialState = {
  isLoading: true,
  workedHours: [],
  employees: [],
  workedHoursForAll: [],
  ...initialFiltersState,
};

export const getAllWorkedHour = createAsyncThunk(
  "workedHour/getAllWorkedHour",
  getAllWorkedHourThunk
);
export const getEmployeesForUser = createAsyncThunk(
  "workedHour/getEmployeesForUser",
  getEmployeesForUserThunk
);

export const getAllWorkedHourForEmployee = createAsyncThunk(
  "workedHour/getAllWorkedHourForEmployee",
  getAllWorkedHourForEmployeeThunk
);
export const updateHour = createAsyncThunk(
  "workedHour/updateHour",
  updateHourThunk
);

const workedHourSlice = createSlice({
  name: "workedHour",
  initialState,
  reducers: {
    handleChange: (state, { payload: { name, value } }) => {
      state[name] = value;
    },
    handleChangeStart: (state, { payload: value }) => {
      state.fra = value;
    },
    handleChangeEnd: (state, { payload: value }) => {
      state.til = value;
    },
    handleChangeFra: (state, { payload: value }) => {
      state.start = value;
    },
    handleChangeTil: (state, { payload: value }) => {
      state.end = value;
    },
    clearValues: () => {
      return {
        ...initialState,
      };
    },
    setEditWorkEvent: (state, { payload }) => {
      return { ...state, isEditing: true, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllWorkedHour.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllWorkedHour.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.workedHoursForAll = payload;
      })
      .addCase(getAllWorkedHour.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(getAllWorkedHourForEmployee.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllWorkedHourForEmployee.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.workedHours = payload;
      })
      .addCase(getAllWorkedHourForEmployee.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(updateHour.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateHour.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        // state.workedHours = payload;
        console.log("payload in fullfill is", payload);
      })
      .addCase(updateHour.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(getEmployeesForUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEmployeesForUser.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.employees = payload;
        state.name = payload[0].name;
      })
      .addCase(getEmployeesForUser.rejected, (state, { payload }) => {
        state.isLoading = false;
        console.log(payload);
        toast.error(payload);
      });
  },
});

export const {
  handleChangeEnd,
  handleChangeStart,
  handleChangeTil,
  handleChangeFra,
  handleChange,
  clearValues,
} = workedHourSlice.actions;

export default workedHourSlice.reducer;
