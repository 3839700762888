// import { showLoading, hideLoading, getAllJobs } from "../allJobs/allJobsSlice";
import customFetch from "../../utils/axios";

export const getAllWorkCheckinOutThunk = async (userId, thunkAPI) => {
  try {
    const resp = await customFetch.get(`/workcheckinout/`, userId);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
};
export const getAllWorkCheckinOutForemployeeThunk = async (
  employeeId,
  thunkAPI
) => {
  try {
    const resp = await customFetch.get(
      `/workcheckinout/getcheckinoutforemployee`,
      employeeId
    );
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
};
export const createWorkCheckinThunk = async (workCheckin, thunkAPI) => {
  try {
    const resp = await customFetch.post("/workcheckinout/checkin", workCheckin);
    return resp.data.msg;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
};
export const createWorkCheckoutThunk = async (workCheckout, thunkAPI) => {
  try {
    const resp = await customFetch.patch(
      "/workcheckinout/checkout",
      workCheckout
    );
    return resp.data.msg;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
};
