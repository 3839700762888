import WorkedHour from "./WorkedHour";
import Wrapper from "../assets/wrappers/WorkedHourContainer";
import { useSelector } from "react-redux";
import Loading from "./Loading";
import WorkedResult from "./WorkedResult";

const WorkedHourContainer = () => {
  const { workedHours, isLoading, fra, til } = useSelector(
    (store) => store.workedHour
  );

  if (isLoading) {
    return <Loading center />;
  }

  if (workedHours.length === 0) {
    return (
      <Wrapper>
        <h2>Der er ikke registeret arbejd i perioden...</h2>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div className="jobs">
        {workedHours.map((workedHour) => {
          return <WorkedHour key={workedHour.id} {...workedHour} />;
        })}
        <WorkedResult workedHours={workedHours} fra={fra} til={til} />
      </div>
    </Wrapper>
  );
};
export default WorkedHourContainer;
