import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  addEmployeeToLocalStorage,
  getEmployeeFromLocalStorage,
  removeEmployeeFromLocalStorage,
} from "../../utils/localStorage";
import {
  loginEmployeeThunk,
  registerEmployeeThunk,
  updateEmployeeThunk,
  clearStoreThunk,
} from "./employeeThunk";

const initialState = {
  isLoading: false,
  employee: getEmployeeFromLocalStorage(),
};

export const registerEmployee = createAsyncThunk(
  "employee/registerEmployee",
  async (employee, thunkAPI) => {
    return registerEmployeeThunk(
      "/employee/employeecreate",
      employee,
      thunkAPI
    );
  }
);

export const loginEmployee = createAsyncThunk(
  "employee/loginUser",
  async (employee, thunkAPI) => {
    return loginEmployeeThunk("/employee/employeelogin", employee, thunkAPI);
  }
);

export const updateEmployee = createAsyncThunk(
  "employee/updateUser",
  async (employee, thunkAPI) => {
    return updateEmployeeThunk("/employee/employeeupdate", employee, thunkAPI);
  }
);
export const clearStore = createAsyncThunk(
  "employee/clearStore",
  clearStoreThunk
);
const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    logoutEmployee: (state, { payload }) => {
      state.employee = null;
      removeEmployeeFromLocalStorage();
      if (payload) {
        toast.success(payload);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerEmployee.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerEmployee.fulfilled, (state, { payload }) => {
        const { employee } = payload;
        console.log("employee is: ", employee.name);
        state.isLoading = false;
        // state.employee = employee;
        // addEmployeeToLocalStorage(employee);
        toast.success(`Medarbejder ${employee.name} er registret!`);
      })
      .addCase(registerEmployee.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(loginEmployee.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginEmployee.fulfilled, (state, { payload }) => {
        const { employee } = payload;
        state.isLoading = false;
        state.employee = employee;
        addEmployeeToLocalStorage(employee);

        toast.success(`Welcome Back ${employee.name}`);
      })
      .addCase(loginEmployee.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(updateEmployee.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateEmployee.fulfilled, (state, { payload }) => {
        const { employee } = payload;
        state.isLoading = false;
        state.employee = employee;
        addEmployeeToLocalStorage(employee);

        toast.success(`User Updated!`);
      })
      .addCase(updateEmployee.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(clearStore.rejected, () => {
        toast.error("There was an error..");
      });
  },
});

export const { logoutEmployee } = employeeSlice.actions;
export default employeeSlice.reducer;
